<template>
  <v-container>
    <v-card>
      <v-card-title
        :class="[this.$vuetify.theme.dark? 'dark-bg' : '']"
        class="
          card-header card-header-title
          font-size-lg
          text-capitalize
          font-weight-normal
        "
      >
        {{ $t("analytics.lang_salesPerHour") }}
      </v-card-title>

      <v-card-text class="mt-6">
        <v-row align="center">
          <v-col>
            <v-text-field
              v-model="date"
              type="date"
              :label="$t('generic.lang_date')"
              dense
              outlined
              hide-details
            />
          </v-col>

          <v-col>
            <v-btn
              :disabled="this.loading || !date"
              :loading="this.loading"
              @click="getSalesData"
              block
              class="bg-primary text-light mx-auto"
            >
              <v-icon class="ma-1">remove_red_eye</v-icon>
              {{ this.$t("generic.lang_display") }}
            </v-btn>
          </v-col>

          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="pa-0 ma-0" />
          </v-col>

          <v-col cols="12" class="mx-auto">
            <v-card v-if="showTopChart">
              <v-card-text>
                <apexchart
                  type="line"
                  height="700"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>

                <div v-if="loading" class="text-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card v-if="showTable">
              <v-card-text class="pa-0">
                <Datatable
                  ref="sales_per_hour"
                  :api-endpoint="ENDPOINTS.DATATABLES.ANALYTICS.SALESPERHOUR"
                  :datatable-headers="datatableHeaders"
                  excel-file-name="sales-per-hours"
                  :data="this.params"
                  :excel-columns="this.excelColumns"
                  :show-select-checkbox="false"
                  :show-search-field="false"
                >

                </Datatable>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import Datatable from "@/components/datatable/Datatable";
import { ENDPOINTS } from "@/config";
import VueApexCharts from "vue-apexcharts";
import { Events } from "@/plugins/events";

var self=null;

export default {
  name: "SalesPerHourStatisticsComponent",
  components: {
    Datatable,
    apexchart: VueApexCharts,
  },
  data: () => ({
    ENDPOINTS,
    date: null,
    loading: false,
    showTopChart: false,
    showTable: false,
    series: null,
    chartOptions: null,
  }),
  computed: {
    excelColumns() {
      return [
        {
          label: this.$t("generic.lang_date"),
          field: "date",
        },
        {
          label: this.$t("generic.lang_day"),
          field: "day",
        },
        {
          label: "00 " + this.$t("generic.lang_oclock"),
          field: "00",
        },
        {
          label: "01 " + this.$t("generic.lang_oclock"),
          field: "01",
        },
        {
          label: "02 " + this.$t("generic.lang_oclock"),
          field: "02",
        },
        {
          label: "03 " + this.$t("generic.lang_oclock"),
          field: "03",
        },
        {
          label: "04 " + this.$t("generic.lang_oclock"),
          field: "04",
        },
        {
          label: "05 " + this.$t("generic.lang_oclock"),
          field: "05",
        },
        {
          label: "06 " + this.$t("generic.lang_oclock"),
          field: "06",
        },
        {
          label: "07 " + this.$t("generic.lang_oclock"),
          field: "07",
        },
        {
          label: "08 " + this.$t("generic.lang_oclock"),
          field: "08",
        },
        {
          label: "09 " + this.$t("generic.lang_oclock"),
          field: "09",
        },
        {
          label: "10 " + this.$t("generic.lang_oclock"),
          field: "10",
        },
        {
          label: "11 " + this.$t("generic.lang_oclock"),
          field: "11",
        },
        {
          label: "12 " + this.$t("generic.lang_oclock"),
          field: "12",
        },
        {
          label: "13 " + this.$t("generic.lang_oclock"),
          field: "13",
        },
        {
          label: "14 " + this.$t("generic.lang_oclock"),
          field: "14",
        },
        {
          label: "15 " + this.$t("generic.lang_oclock"),
          field: "15",
        },
        {
          label: "16 " + this.$t("generic.lang_oclock"),
          field: "16",
        },
        {
          label: "17 " + this.$t("generic.lang_oclock"),
          field: "17",
        },
        {
          label: "18 " + this.$t("generic.lang_oclock"),
          field: "18",
        },
        {
          label: "19 " + this.$t("generic.lang_oclock"),
          field: "19",
        },
        {
          label: "20 " + this.$t("generic.lang_oclock"),
          field: "20",
        },
        {
          label: "21 " + this.$t("generic.lang_oclock"),
          field: "21",
        },
        {
          label: "22 " + this.$t("generic.lang_oclock"),
          field: "22",
        },
        {
          label: "23 " + this.$t("generic.lang_oclock"),
          field: "23",
        },
      ];
    },
    datatableHeaders() {
      return [
        {
          text: this.$t("generic.lang_date"),
          value: "date",
        },
        {
          text: this.$t("generic.lang_day"),
          value: "day",
        },
        {
          text: this.$t("00"),
          value: "00",
        },
        {
          text: this.$t("01"),
          value: "01",
        },
        {
          text: this.$t("02"),
          value: "02",
        },
        {
          text: this.$t("03"),
          value: "03",
        },
        {
          text: this.$t("04"),
          value: "04",
        },
        {
          text: this.$t("05"),
          value: "05",
        },
        {
          text: this.$t("06"),
          value: "06",
        },
        {
          text: this.$t("07"),
          value: "07",
        },
        {
          text: this.$t("08"),
          value: "08",
        },
        {
          text: this.$t("09"),
          value: "09",
        },
        {
          text: this.$t("10"),
          value: "10",
        },
        {
          text: this.$t("11"),
          value: "11",
        },
        {
          text: this.$t("12"),
          value: "12",
        },
        {
          text: this.$t("13"),
          value: "13",
        },
        {
          text: this.$t("14"),
          value: "14",
        },
        {
          text: this.$t("15"),
          value: "15",
        },
        {
          text: this.$t("16"),
          value: "16",
        },
        {
          text: this.$t("17"),
          value: "17",
        },
        {
          text: this.$t("18"),
          value: "18",
        },
        {
          text: this.$t("19"),
          value: "19",
        },
        {
          text: this.$t("20"),
          value: "20",
        },
        {
          text: this.$t("21"),
          value: "21",
        },
        {
          text: this.$t("22"),
          value: "22",
        },
        {
          text: this.$t("23"),
          value: "23",
        },
      ];
    },
    params() {
      return this.getDate();
    },
  },
  mounted() {
    self = this;
  },
  methods: {
    async getSalesData() {
      this.loading = true;
      this.showTopChart = false;
      this.showTable = false;
      this.series = [
        {
          name: this.$t("generic.lang_sales"),
          data: [],
        },
      ];
      this.chartOptions = {
        theme: {
          mode: this.$vuetify.theme.dark? 'dark' : 'light', 
        },
        stroke: {
          curve: "smooth",
        },
        yaxis: [
          {
            title: {
              text: this.$t("generic.lang_totalInEuro"),
            },
            labels: {
              formatter: function (val) {
                return self.$options.filters.currency(val);
              },
            },
          },
        ],
        xaxis: {
          title: {
            text: this.$t("generic.lang_hour"),
          },
          labels: {
            formatter: function (val) {
              return val + ":00h";
            },
          },
          categories: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
          ],
        },
      };

      var config = {
        method: "get",
        url: `${ENDPOINTS.DATATABLES.ANALYTICS.SALESPERHOUR}`,
        headers: {
          "Content-Type": "application/json",
        },
        params: this.params,
      };

      await this.axios(config)
        .then((res) => {
          if (res.status == 200) {
            var data = res.data.aaData;

            if ((Array.isArray(data) && !data.length) || data == null) {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_noDataToDisplay"),
                color: "warning",
              });
              return;
            }

            for (let i = 2; i < data[0].length; i++) {
              this.series[0].data.push(data[0][i]);
            }

            if (this.$refs.sales_per_hour) {
              this.$refs.sales_per_hour.getDataFromApi();
            }

            this.showTopChart = true;
            this.showTable = true;

            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_dataLoading"),
              color: "success",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: res.data.msg || this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDate() {
      let startEndDate = new Date(this.date);

      return {
        start_date:
          this.date == "" || this.date == null
            ? null
            : moment
                .unix(startEndDate.getTime() / 1000)
                .startOf("days")
                .unix(),

        end_date:
          this.date == "" || this.date == null
            ? null
            : moment
                .unix(startEndDate.getTime() / 1000)
                .endOf("days")
                .unix(),
      };
    },
  },
};
</script>

<style scoped>
</style>
